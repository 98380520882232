$(document).ready(function(){
    try {
        var max_sale = document.getElementById("max_sale");
        var is_total_set = document.getElementById("is_total_set");

        max_sale.disabled = is_total_set.checked;

        $(is_total_set).change(function () {
            max_sale.disabled = is_total_set.checked;
        });
    }catch (err){
        //ignore
    }
});
