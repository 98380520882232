import Swal from 'sweetalert2'

$(function () {
  'use strict'

  $('#afmelden').click(function (e) {
    e.preventDefault()

    Swal.fire({
      title: 'Weet je het zeker?',
      text: 'Na afmelden is het niet meer mogelijk om je  online aan te melden',
      icon: 'warning',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, afmelden',
      cancelButtonText: 'Nee, aangemeld blijven',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#999',
    }).then((result) => {
      if (result.value) {
        window.location = $('#afmelden').attr('href')
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })

  })

  const successMsg = $('.alert-success')
  if (successMsg.length) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      type: 'success',
      title: successMsg.text(),
      showConfirmButton: false,
      timer: 2500
    })
  }

  const errorMsg = $('.alert-danger')
  if (errorMsg.length) {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      type: 'error',
      title: errorMsg.text(),
      showConfirmButton: false,
      timer: 2500
    })
  }

  $('#verwijderen').click(function (e) {
    e.preventDefault()

    Swal.fire({
      title: 'Weet je het zeker?',
      text: 'Na verwijderen is werknemer niet meer zichtbaar',
      icon: 'error',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijderen',
      cancelButtonText: 'Nee, behouden',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#999',
    }).then((result) => {
      if (result.value) {
        window.location = $('#verwijderen').attr('href')
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })

  })

  'use strict'

  $('.delete-warning').click(function (e) {
    e.preventDefault()
    var _this = $(this)
    var warning_txt = $(this).data('warning-txt') ? $(this).data('warning-txt') : ''
    var warning_btn = $(this).data('warning-btn') ? $(this).data('warning-btn') : 'Yes, Delete it!'

    Swal.fire({
      title: 'Are you sure?',
      text: warning_txt,
      icon: 'warning',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: warning_btn,
    }).then((result) => {
      if(result.value) {
        if ($(e.target).closest('a').length) {
          location.href = _this.attr('href')
          // console.log('link')
        } else {
          $(e.target).closest('form').submit();
          // console.log('submit')
        }
      }
    })
  })

    $('.datum-popup').click(function (e) {
        e.preventDefault()
        var _this = $(this)
        var warning_txt = $(this).data('warning-txt') ? $(this).data('warning-txt') : ''
        var warning_btn = $(this).data('warning-btn') ? $(this).data('warning-btn') : 'Yes, Change it!'
        var datum = $(this).data('datum');

        if(datum != document.getElementById("datepicker").value){
            Swal.fire({
                title: 'Are you sure you want to change the date?',
                text: warning_txt,
                icon: 'warning',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: warning_btn,
                showCloseButton: true,
            }).then((result) => {
                if (result.value) {
                    $('#sendEmail-id').val("on");
                    $(e.target).closest('form').submit();
                } else if (result['dismiss'] === 'close') {

                } else {
                    $(e.target).closest('form').submit();
                }
            })
        }else {
            $(e.target).closest('form').submit();
        }
    });


    'use strict'
    $('.sold-out').click(function (e) {
        e.preventDefault()
        var _this = $(this)
        var warning_txt = $(this).data('warning-txt') ? $(this).data('warning-txt') : ''
        var warning_btn = $(this).data('warning-btn') ? $(this).data('warning-btn') : 'Yes!'
            Swal.fire({
                title: 'Are you sure you want to set the ticket to soldout?',
                text: warning_txt,
                icon: 'warning',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: warning_btn,
            }).then((result) => {
                if(result.value) {
                    if ($(e.target).closest('a').length) {
                        location.href = _this.attr('href')

                    } else {
                        $(e.target).closest('form').submit();
                    }
                } else{
                }
            })
    })
})
