$(function () {
  'use strict'
  var table = $('.datatable').DataTable({
    "order": [
        [1, 'desc']
    ],
    "language" : {
        "zeroRecords": " "
    },
    "displayLength": 35,
  });

    var helpdeskTable = $('.helpdesk-datatable').DataTable({
        "order": [
            [3, 'desc']
        ],
        "language" : {
            "zeroRecords": " "
        },
        "displayLength": 35,
    });
})
