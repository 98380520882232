// $("ticket_type").change(function () {
//     if ($(this).val() === "Free") {
//         $("ticket_prijs").attr("disabled", "disabled");
//     } else {
//         $("ticket_prijs").removeAttr("disabled");
//     }
// });

$(document).ready(function () {

    $("#ticket_type").on("change", function() {
        if ($(this).val() === "Free") {
            $("#ticket_prijs").val(0);
            $("#ticket_prijs").attr("readonly", "readonly");

            $("#payment_type").val('normal');
            $("#payment_type").attr("readonly", "readonly");
            $("#payment_type").css("pointer-events","none");
        } else {
            $("#ticket_prijs").val('');
            $("#ticket_prijs").removeAttr("readonly");

            $("#payment_type").val('cp');
            $("#payment_type").removeAttr("readonly");
            $("#payment_type").css("pointer-events","");
        }
    });
})
