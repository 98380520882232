import Chart from 'chart.js'

$(function () {

    if (typeof salesData !== 'undefined') {

      var ctx = document.getElementById('sales-chart')

      if (ctx) {
        var salesDataChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: salesDataLabels,
            datasets: [{
              label: 'Sales',
              data: salesData
            }]
          },
          options: {
            legend: {
              display: false
            },
            tooltips: {
              enabled: false
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                },
                gridLines: {
                  display: true,
                  color: '#eeeeee'
                }
              }],
              xAxes: [{
                gridLines: {
                  display: false
                }
              }],
            },

          }

        })
      }

      var ctxgender = document.getElementById('genderDonut')
      if (ctxgender) {
        var genderDonut = new Chart(ctxgender, {
          type: 'doughnut',
          data: {
            datasets: [{
              data: genderData,
              backgroundColor: [
                '#36a2eb',
                '#ff6384',
                '#ff0034',
              ]
            }],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: [
              'Man',
              'Woman',
              'Other',
            ]
          }
        })
      }

      //
      // Morris.Area({
      //   element: 'morris-area-chart2',
      //   data: salesData,
      //   xkey: 'period',
      //   ykeys: ['count'],
      //   labels: ['count'],
      //   pointSize: 0,
      //   fillOpacity: 0.4,
      //   pointStrokeColors: ['#b4becb', '#01c0c8'],
      //   behaveLikeLine: true,
      //   gridLineColor: '#e0e0e0',
      //   lineWidth: 0,
      //   smooth: false,
      //   hideHover: 'auto',
      //   lineColors: ['#01c0c8', '#01c0c8'],
      //   resize: true
      //
      // })
    }

    /*

            Affiliate statistics chart

     */

    if (typeof affiliateSalesData !== 'undefined') {

        var ctx = document.getElementById('affiliate-chart')

        if (ctx) {
            var salesDataChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: salesDataLabels,
                    datasets: [{
                        label: 'Sales',
                        data: affiliateSalesData
                    }]
                },
                options: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                stepSize: 1,
                                beginAtZero: true
                            },
                            gridLines: {
                                display: true,
                                color: '#eeeeee'
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }],
                    },

                }

            })
        }
    }

    /*

            Main trackers page chart

     */

    if (typeof totalTrackerClicksData !== 'undefined') {
        var ctxTotalTrackerClicks = document.getElementById('totalTrackerClicks');
        var totalTrackerClicks = new Chart(ctxTotalTrackerClicks, {
            type: 'line',
            data: {
                labels: totalTrackerClicksDataLabels,
                datasets: [{
                    label: '# of clicks',
                    data: totalTrackerClicksData,
                    backgroundColor: [
                        'rgba(255, 255, 255, 0)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'monthly total clicks'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    /*

            View tracker page charts

     */

    if (typeof yearlyClicksData !== 'undefined') {
        var ctxTrackerClicksYear = document.getElementById('trackerClicksChartYear');
        var TrackerClicksYear = new Chart(ctxTrackerClicksYear, {
            type: 'line',
            data: {
                labels: yearlyClicksDataLabels,
                datasets: [{
                    label: '# of clicks',
                    data: yearlyClicksData,
                    backgroundColor: [
                        'rgba(255, 255, 255, 0)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'total clicks last year'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        var ctxTrackerClicksMonth = document.getElementById('trackerClicksChartMonth');
        var TrackerClicksMonth = new Chart(ctxTrackerClicksMonth, {
            type: 'line',
            data: {
                labels: monthlyClicksDataLabels,
                datasets: [{
                    label: '# of clicks',
                    data: monthlyClicksData,
                    backgroundColor: [
                        'rgba(255, 255, 255, 0)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'total clicks last 30 days'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        var ctxTrackerClicksWeek = document.getElementById('trackerClicksChartWeek');
        var TrackerClicksWeek = new Chart(ctxTrackerClicksWeek, {
            type: 'line',
            data: {
                labels: weeklyClicksDataLabels,
                datasets: [{
                    label: '# of clicks',
                    data: weeklyClicksData,
                    backgroundColor: [
                        'rgba(255, 255, 255, 0)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'total clicks last 7 days'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        var ctxTrackerClicksDay = document.getElementById('trackerClicksChartDay');
        var TrackerClicksDay = new Chart(ctxTrackerClicksDay, {
            type: 'line',
            data: {
                labels: dailyClicksDataLabels,
                datasets: [{
                    label: '# of clicks',
                    data: dailyClicksData,
                    backgroundColor: [
                        'rgba(255, 255, 255, 0)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'total clicks last 24 hours'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        var ctxPlatformTypes = document.getElementById('platformDonut');
        var platformTypes = new Chart(ctxPlatformTypes, {
            type: 'doughnut',
            data: {
                labels: platformTypeDataLabels,
                datasets: [{
                    label: 'Platforms',
                    data: platformTypeData,
                    backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA", "#BBBBBB"]
                }]
            },
            options: {
                responsive: true
            }
        });

        var ctxBrowserTypes = document.getElementById('browserDonut');
        var browserTypes = new Chart(ctxBrowserTypes, {
            type: 'doughnut',
            data: {
                labels: browserTypeDataLabels,
                datasets: [{
                    label: 'Browsers',
                    data: browserTypeData,
                    backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA", "#BBBBBB"]
                }]
            },
            options: {
                responsive: true
            }
        });
    }
  }
)
