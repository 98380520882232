$(function () {
    'use strict'
    $('body').on('submit', 'form#modal-ajax', function (e) {
        e.preventDefault()
        if ($(this).find('.btn').hasClass('disabled')) {
            return false
        }
        const _this = $(this);
        $(this).find('.btn').parent().prepend('<i class="fa fa-spinner fa-spin mr-1"></i>')
        $(this).find('.btn').addClass('disabled')
        $.ajax({
            url: $('form#modal-ajax').attr('action'),
            data: $(this).serialize(),
            method: 'POST',
            success: function (data) {
                $('#htmlModal').modal('hide')
                if (data.url) {
                    window.location.href = data.url
                }
                _this.find('.btn').removeClass('disabled')
                _this.find('.fa-spin').remove()
            },
            error: function (request, textStatus, errorThrown) {
                const json = $.parseJSON(request.responseText)
                const errorbox = $('#htmlModal').children().find('.modal-body .error')
                _this.find('.btn').removeClass('disabled')
                _this.find('.fa-spin').remove()
                errorbox.html('')
                $.each(json.errors, function (key, value) {
                    errorbox.show()
                    errorbox.append('<p class="mb-1">' + value + '</p>')
                })
                $('#htmlModal').modal('hide')
            },
            dataType: 'json'
        })
    });

    $('body').on('change', '#events_select', function (e) {
        const selectedOption = $(this).children("option:selected");
        const availablePayout = selectedOption.data('available-payout');
        const selectedValue = selectedOption.val()

        if(selectedValue !== "default"){
            $.ajax({
                type: 'GET',
                url: '/dashboard/wallet/checkForRefundRequest',
                data:{event_id: selectedValue},
                success:function(result){
                    console.log(result);
                    if(!result){
                        $('.AmountInput').show();
                        document.getElementById("payoutnumber").placeholder = availablePayout;
                        document.getElementById("payoutnumber").max = availablePayout;
                        document.getElementById("payoutnumber2").innerText = "Max " + availablePayout;
                    }else{
                        $('#refundMessage').show();
                    }
                },
                error:function(){
                    console.log('An Error has occurred');
                }
            });
        }else{
            $('.AmountInput').hide();
            alert("Please select an event");
        }
    });

    $(document).ready(function(){
        $(document).on('change', '#organisation_select', function(){
            var org_id=$(this).val();

                if(org_id != 'default'){
                    var div = $(this).parent();

                    var op =" ";

                    $.ajax({
                        type: 'get',
                        url: 'finance/findEventName',
                        data:{'id': org_id},
                        success:function(data){
                            op+='<option value="default" selected>Select an event</option>';
                            for(var i = 0; i<data.data.length; i++){
                                op+='<option value="'+data.data[i].id+'" data-available-payout="'+data.saldos[i].saldo+'">'+data.data[i].title+'</option>';
                            }

                            div.find('#event_select').html(" ");
                            div.find('#event_select').append(op);

                            for (var i = 0; i < data.banks[0].bank.length; i++) {
                                $('#bank_select').append('<option value="' + data.banks[0].bank[i].id + '">' + data.banks[0].bank[i].name + '</option>');
                            }
                            div.find('#bank_select').html(" ");
                            div.find('#bank_select').append(op);

                        },
                        error:function(){
                            console.log('error');
                        }
                    });
                } else{
                    $('.AmountInput').hide();
                    alert("Please select an organisation");
                }
        });
        $(document).on('change', '#event_select', function(){
            const selectedOption = $(this).children("option:selected");
            const availablePayout = selectedOption.data('available-payout');
            const selectedValue = selectedOption.val();
            if(selectedValue != "default"){
                $('.AmountInput').show();
                 document.getElementById("payoutnumber").placeholder = availablePayout;
                 document.getElementById("payoutnumber").max = availablePayout;
                 document.getElementById("payoutnumber2").innerText = "Max " + availablePayout;
            }else{
                $('.AmountInput').hide();
                alert("Please select an event");
            }
        });
    });

    $(document).ready(function () {
        $(document).on('change', '#discount_lvl', function () {
            const selectedOption = $(this).children("option:selected");
            const event_id = selectedOption.data('available-test');
            const selectedValue = selectedOption.val();
            var ticket_type_select = document.getElementById("ticket_type");
            var ticket_type = ticket_type_select.options[ticket_type_select.selectedIndex].value;

            if(selectedValue != 'default') {
                $.ajax({
                    type: 'get',
                    url: '/dashboard/events/' + event_id + '/discounts/create/lvl',
                    data: {'id': event_id},
                    success: function (data) {
                       if (selectedValue !== '2') {

                           $('#ticket_id').html(" ");
                           $('#tickets_select_div').show();
                           console.log('ajax success');
                           for (var i = 0; i < data.data.length; i++) {
                               if(ticket_type == data.data[i].ticket_type){
                                   $('#ticket_id').append('<option value="' + data.data[i].id + '">' + data.data[i].name + '</option>')
                               }
                           }
                       } else{
                           $('#tickets_select_div').hide();
                       }
                    },
                    error: function () {
                        console.log('error');
                    }
                });
            } else {
                alert("Please choose a discount type");
                $('#tickets_select_div').hide();
            }
        });
        $(document).on('change', '#ticket_type', function () {
            const selectedOption = document.getElementById("discount_lvl");
            const selectedValue = selectedOption.options[selectedOption.selectedIndex].value;
            const event_id = selectedOption.options[selectedOption.selectedIndex].getAttribute('data-available-test');

            var ticket_type_select = document.getElementById("ticket_type");
            var ticket_type = ticket_type_select.options[ticket_type_select.selectedIndex].value;

            console.log(selectedValue);
            if (selectedValue != 'default') {
                $.ajax({
                    type: 'get',
                    url: '/dashboard/events/' + event_id + '/discounts/create/lvl',
                    data: {'id': event_id},
                    success: function (data) {
                        if(selectedValue !== '2'){
                            //todo ben hier nog mee bezig
                            console.log(selectedValue)

                            $('#ticket_id').html(" ");
                            $('#tickets_select_div').show();
                            console.log('ajax success');
                            var count = 0;
                            for (var i = 0; i < data.data.length; i++) {
                                console.log(data.data[i].ticket_type + ' ' + ticket_type);
                                if(ticket_type.toLowerCase() == data.data[i].ticket_type.toLowerCase()){
                                    $('#ticket_id').append('<option value="' + data.data[i].id + '">' + data.data[i].name + '</option>')
                                    count++;
                                }
                            }
                            if(count == 0){
                                $('#tickets_select_div').hide();
                            }

                        } else{
                            $('#tickets_select_div').hide();
                        }

                    },
                    error: function () {
                        console.log('error');
                    }
                });
            } else {
                alert("Please choose a discount type");
                $('#tickets_select_div').hide();
            }
        });
    });

    $(document).ready(function () {
        var account_type = $('#account_type').val();

        if (account_type !== 'default') {
            if (account_type === '2') {
                $('#companyName').show();
                $('#birthday').hide();
            }else{
                $('#companyName').hide();
                $('#birthday').show();
            }
        }

            $(document).on('change', '#account_type', function () {
            const selectedOption = $(this).children("option:selected");
            const selectedValue = selectedOption.val();
            if (selectedValue !== 'default') {
                $.ajax({
                    type: 'get',
                    url: '/register',
                    success: function() {
                        if (selectedValue === '2') {
                            $('#companyName').show();
                            $('#birthday').hide();
                        }else{
                            $('#companyName').hide();
                            $('#birthday').show();
                        }
                    },
                    error: function () {
                        console.log('error');
                    }
                });
            } else {
                alert("Please choose a account type");
                $('.myDiv').hide();
            }
        });
    });
    $(document).ready(function () {
        $(document).on('change', '#payment_type', function () {
            const selectedOption = $(this).children("option:selected");
            const selectedValue = selectedOption.val();
            console.log(selectedValue);
            if (selectedValue !== 'default') {
                $.ajax({
                    type: 'get',
                    url: '/register',
                    success: function (data) {
                        console.log('test')
                        if (selectedValue === 'termijn') {

                            $('#test').show();
                            $('#first-installment').show();
                            console.log('ajax success');
                        }else{
                            $('#test').hide()
                            $('#first-installment').hide();
                            $('#company_name').html(" ");
                        }
                    },
                    error: function () {
                        console.log('error');
                    }
                });
            } else {
                alert("Please choose a discount type");
                $('.myDiv').hide();
            }
        });
    });
    $(document).ready(function () {
        $(document).on('change', '#ticket_prijs', function () {

            // const selectedOption = $(this).children("option:selected");
            var selectedValue = ($(this).val());

            var transaction_fee_val = ((selectedValue / 100) * 3.4);
            var total = parseFloat(selectedValue) + parseFloat(transaction_fee_val);

            $('#ticket_prijs_transaction_fee').html(total.toFixed(2));
            $('#ticket_prijs_transaction_fee').val(total.toFixed(2));

        });
    });
});



