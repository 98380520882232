
if ($('#searchTickets').length > 0) {
  var running = false
  $('#searchTickets').focus()
  $('#searchTickets').keyup(function (e) {
    
    var code = $(this).val()
    var event_id = $('#event_id').val()
    
    if ((code.length > 19 || e.which == 13) && running === false && code.length > 3) {
      running = true
      $('.resultBox').removeClass('bg-info').removeClass('bg-danger').removeClass('bg-success')
      $.ajax({
        type: 'POST',
        url: '/dashboard/ticket-scanner/' + event_id,
        data: {code: code, _method: 'PUT'},
        success: function (data) {
          running = false
          $('#searchTickets').val('')
          $('#searchTickets').focus()
          
          if (data.error) {
            return alert('Event not found!')
          }
          
          if (typeof data.ticket.scanned !== 'undefined') {
            
            if (!data.ticket.scanned) {
              $('.resultBox').addClass('bg-success').html('<h3>Ticket valid</h3>')
            } else {
              $('.resultBox').addClass('bg-danger').html('<h3>Ticket used at ' + data.ticket.scanned_at + '</h3>')
            }
            
            var $clientdata = '<h3>' + data.user.first_name + ' ' + data.user.last_name + '</h3>'
            // +'<br/>' + data.user.email + '<br/>' +data.user.phone
            // +'<br/>' + data.user.address + '<br/>' +data.user.zipcode+ ', ' +data.user.city;
            
            $('#clientInfo').html($clientdata)
            $('#ticketBought').html('<h3>' + data.ticket.created_at + '</h3>')
            $('#ticketUsed').html('<h3>' + data.ticket.scanned_at + '</h3>')
            $('#ticketPayment').html('<h3>' + data.ticket.price + '</h3>')
            $('#ticketName').html('<h3>' + data.ticket.type + '</h3>')
            
          } else {
            $('#clientInfo').html('')
            $('#ticketBought').html('')
            $('#ticketUsed').html('')
            $('#ticketPayment').html('')
            $('#ticketName').html('')
            
            $('.resultBox').addClass('bg-danger').html('<h3>No ticket found with ' + code + '</h3>')
            
          }
        },
        done: function () {
        
        },
        error: function (e) {
          running = false
        },
        dataType: 'json'
      })
    }
    
  })
  
}
