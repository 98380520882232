$(function () {
  'use strict'

  function buildPreview () {
    $('#ticketDesignPreview').parent().find('h2').append(' <i class="fa fa-spin  fa-spinner pull-right"></i>')
    var data = new $('#TicketDesignerForm').serializeArray()
    data.push({name: 'topImg', value: $('.previewImg img').attr('src')})

      $.ajax({
      type: 'POST',
      url: window.location.href + '/preview',
      data: data,
      success: function (data) {
        $('#ticketDesignPreview').html('<object data="' + data.preview + '" type="application/pdf" width="100%" height="800">').parent().find('.fa-spinner').remove()
      },
      error: function (e) {
        $('#ticketDesignPreview').html('<div class="alert alert-danger"> An error has occurred: The preview cannot be dislayed. Please try again later. </div>')
        $('#ticketDesignPreview').parent().find('.fa-spinner').remove()
      },
    })
  }

  if ($('#ticketDesignPreview').length) {
    buildPreview()
    $('#RenderDesign').click(function (e) {
      e.preventDefault()
      buildPreview()
    })

  }

})
