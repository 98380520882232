$(function () {
    var $uploadCrop;

    function readFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            var parent = $(input).parent().parent().parent();

            reader.onload = function (e) {
                parent.addClass('ready');
                $uploadCrop.croppie('bind', {
                    url: e.target.result
                }).then(function () {
                    console.log('jQuery bind complete');
                });
            };

            reader.readAsDataURL(input.files[0]);
        } else {
            swal('Sorry - you\'re browser doesn\'t support the FileReader API');
        }
    }

    $uploadCrop = $('#upload-company-logo').croppie({
        viewport: {
            width: 240,
            height: 150
        },
        boundary: {
            width: 240,
            height: 150
        },
        enableExif: true,
        enableResize: true,
    });
    $('#upload-company-logo-file').on('change', function () {
        readFile(this);
    });
    $('.upload-company-logo-result').on('click', function (ev) {
        var mainDiv = $(this).parent().parent();
        var uploadField = $(this).parent().parent().find('input.cropy-img-data');
        ev.preventDefault();
        $uploadCrop.croppie('result', {
            type: 'base64',
            size: 'viewport'
        }).then(function (resp) {
            console.log(resp);
            uploadField.val(resp);
            mainDiv.find('.upload-company-logo-msg img').attr('src', resp);
            mainDiv.removeClass('ready');
        });
    });
});
