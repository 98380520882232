
$('#applyDiscountBtn').on('click', function () {
    const discountCode = document.getElementById('discount_input').value;
    const eventId = this.getAttribute('data-eventid');
    const transactie = this.getAttribute('data-transactie');

    const productsSelected = document.getElementById('productsSelected').value;
    var ticketsArray = [];

    if(productsSelected){
        ticketsArray = JSON.parse(productsSelected);
    }

    if(discountCode != ''){
        $.ajax({
            type: 'get',
            url: 'frame/shop/checkdiscount',
            data: {'discountcode': discountCode, 'eventid': eventId},
            success: function (data) {
                //checking if there has been found a discount code with the input
                if(data.success == true){
                    $('#discount_error').html('<div class="alert alert-success">The discount code is applied. </div>')
                    $('#discount_error').parent().find('.fa-spinner').remove()
                    var transactie_fee = 0;
                    switch(data.discount.discount_type) {
                        //discount if it has a percentage value
                        case 'percentage':
                            switch (data.type) {
                                case 'global':

                                    var value = parseFloat(data.discount.discount_value);

                                    var discount = (100 - value) / 100;
                                    var total = 0;
                                    var discountTotal = 0;
                                    var totalfee = 0;
                                    var discountUse = false;

                                    var discountTickets = [];

                                    //calculating the total and totalfee
                                    for (var i = 0; i < ticketsArray.length; i++){

                                        //checking if the type is the same for the discount and the ticket
                                        if(data.discount.ticket_type == ticketsArray[i][5]){
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            discountTotal = discountTotal + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                            discountUse = true;
                                        }else{
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            total = total + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                        }
                                    }

                                    //calculating the discount value for the reservation
                                    var discount_value = (value / 100) * discountTotal;

                                    //calculating total amount of discount tickets
                                    var totalAmount = 0;
                                    for(var j = 0; j < ticketsArray.length; j++){
                                        if(data.discount.ticket_type == ticketsArray[j][5]){
                                            totalAmount = totalAmount + ticketsArray[j][1];
                                        }
                                    }
                                    //calculating discount per ticket
                                    var discountOneTicket = (discount_value / totalAmount);

                                    //saving ticketid + the discount for that ticket in array for reservation
                                    for (var k = 0; k < ticketsArray.length; k++){
                                        if(data.discount.ticket_type == ticketsArray[k][5]){
                                             discountTickets.push([ticketsArray[k][0],discountOneTicket.toFixed(2)])
                                        }
                                    }

                                    document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);




                                    //calculating the discount total minus the discount
                                    transactie_fee = 0;

                                    if (transactie){
                                        transactie_fee = price / 100 * 3.4;
                                        total += transactie_fee;
                                    }
                                    discountTotal = discountTotal * discount;
                                    total = total + discountTotal + totalfee;

                                    //filling hiddeninputs for the reservation
                                    document.getElementById('discount_check').value = 'true';
                                    document.getElementById('total_discount').value = discount_value.toFixed(2);

                                    //setting the information in the elements
                                    document.getElementById('discount_total').innerText = total.toFixed(2);
                                    document.getElementById('discount_div').style.display = 'block';
                                    document.getElementById('discount').innerText = discount_value.toFixed(2);
                                    break;

                                    //level 2: discount over the complete total of all the products of specific event
                                case 'event':

                                    var value = parseFloat(data.discount.discount_value);

                                    var discount = (100 - value) / 100;
                                    var total = 0;
                                    var discountTotal = 0;
                                    var totalfee = 0;
                                    var discountUse = false;

                                    var discountTickets = [];

                                    //calculating the total and totalfee
                                    for (var i = 0; i < ticketsArray.length; i++){

                                        //checking if the type is the same for the discount and the ticket
                                        if(data.discount.ticket_type == ticketsArray[i][5]){
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            discountTotal = discountTotal + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                            discountUse = true;
                                        }else{
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            total = total + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                        }
                                    }

                                    //calculating the value of the discount for the reservation
                                    var discount_value = (value / 100) * discountTotal;

                                    //calculating total amount of discount tickets
                                    var totalAmount = 0;
                                    for(var j = 0; j < ticketsArray.length; j++){
                                        if(data.discount.ticket_type == ticketsArray[j][5]){
                                            totalAmount = totalAmount + ticketsArray[j][1];
                                        }
                                    }
                                    //calculating discount per ticket
                                    var discountOneTicket = (discount_value / totalAmount);

                                    //saving ticketid + the discount for that ticket in array for reservation
                                    for (var k = 0; k < ticketsArray.length; k++){
                                        if(data.discount.ticket_type == ticketsArray[k][5]){
                                            discountTickets.push([ticketsArray[k][0],discountOneTicket.toFixed(2)])
                                        }
                                    }
                                    document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);


                                    //calculating the discount total minus the discount
                                    transactie_fee = 0;

                                    if (transactie){
                                        transactie_fee = price / 100 * 3.4;
                                        total += transactie_fee;
                                    }
                                    discountTotal = discountTotal * discount;
                                    total = total + discountTotal + totalfee;

                                    document.getElementById('discount_check').value = 'true';
                                    document.getElementById('total_discount').value = discount_value.toFixed(2);

                                    document.getElementById('discount_total').innerText = total.toFixed(2);
                                    document.getElementById('discount_div').style.display = 'block';
                                    document.getElementById('discount').innerText = discount_value.toFixed(2);

                                    break;

                                    //discount if it is per ticket
                                case 'ticket':
                                    switch (data.discount.discount_lvl) {

                                        //level 1: discount on only one ticket
                                        case 1:

                                            var value = parseFloat(data.discount.discount_value);
                                            var ticketId = data.discount.ticket_id;
                                            var discount = 0;

                                            var discountTickets = [];

                                            //calculating the discount
                                            for(var i = 0; i < ticketsArray.length; i++){
                                                if(ticketsArray[i][0] == ticketId){
                                                    discount = (value / 100) * parseFloat(ticketsArray[i][2]);
                                                    discountTickets.push([ticketsArray[i][0], discount.toFixed(2)])
                                                }
                                            }

                                            var total = 0;
                                            var totalfee = 0;

                                            //calculating the total and totalfee
                                            for (var i = 0; i < ticketsArray.length; i++){
                                                var amount = ticketsArray[i][1];
                                                var price = ticketsArray[i][2];
                                                var ticketfee = parseFloat(ticketsArray[i][3]);

                                                total = total + (amount * price) + (amount * ticketfee);
                                                totalfee = totalfee + (amount * ticketfee);
                                            }
                                            transactie_fee = 0;

                                            if (transactie){
                                                transactie_fee = price / 100 * 3.4;
                                                total += transactie_fee;
                                            }

                                            total = total - discount;

                                            // document.getElementById('tickets_discounts').value = discount;
                                            document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);

                                            document.getElementById('discount_check').value = 'true';
                                            document.getElementById('total_discount').value = discount.toFixed(2);

                                            document.getElementById('discount_total').innerText = total.toFixed(2);
                                            document.getElementById('discount_div').style.display = 'block';
                                            document.getElementById('discount').innerText = discount.toFixed(2);

                                            break;
                                        case 2:
                                            $('#discount_error').html('<div class="alert alert-danger">This discount type needs to be used later. </div>')
                                            $('#discount_error').parent().find('.fa-spinner').remove()
                                            break;
                                            //level 3: discount on all the tickets of a specific ticket
                                        case 3:
                                            var value = parseFloat(data.discount.discount_value);
                                            var ticketId = data.discount.ticket_id;
                                            var discount = 0;

                                            var discountTickets = [];

                                            var discountOneTicket = 0;
                                            //calculating the discount
                                            for(var i = 0; i < ticketsArray.length; i++){
                                                if(ticketsArray[i][0] == ticketId){
                                                    discount = ((value / 100) * parseFloat(ticketsArray[i][2])) * ticketsArray[i][1];
                                                    discountOneTicket = discount / ticketsArray[i][1];
                                                    discountTickets.push([ticketsArray[i][0],discountOneTicket.toFixed(2)])
                                                }
                                            }

                                            var total = 0;
                                            var totalfee = 0;

                                            //calculating the total and totalfee
                                            for (var i = 0; i < ticketsArray.length; i++){
                                                if(ticketId == ticketsArray[i][0]){
                                                    var amount = ticketsArray[i][1];
                                                    var price = ticketsArray[i][2];
                                                    var ticketfee = parseFloat(ticketsArray[i][3]);

                                                    total = total + (amount * price) + (amount * ticketfee);
                                                    totalfee = totalfee + (amount * ticketfee);

                                                    total = total - discount;
                                                } else{
                                                    var amount = ticketsArray[i][1];
                                                    var price = ticketsArray[i][2];
                                                    var ticketfee = parseFloat(ticketsArray[i][3]);

                                                    total = total + (amount * price) + (amount * ticketfee);
                                                    totalfee = totalfee + (amount * ticketfee);


                                                }

                                            }
                                            transactie_fee = 0;

                                            if (transactie){
                                                transactie_fee = price / 100 * 3.4;
                                                total = total + transactie_fee;
                                            }
                                            //give hidden inputs value for the reservation
                                            document.getElementById('discount_check').value = 'true';
                                            document.getElementById('total_discount').value = discount.toFixed(2);
                                            document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);


                                            // document.getElementById('tickets_discounts').value = discount;
                                            document.getElementById('discount_total').innerText = total.toFixed(2);
                                            document.getElementById('discount_div').style.display = 'block';
                                            document.getElementById('discount').innerText = discount.toFixed(2);
                                            break;
                                    }
                                    break;
                            }
                            break;

                            //discount if it has a euro value
                        case 'euro':
                            switch (data.type) {
                                case 'global':
                                    const event_list = data.discount.event_id_list;

                                    //checking if the discount includes this event id
                                    if(event_list.includes(parseInt(eventId))){
                                        var discount = parseFloat(data.discount.discount_value);

                                        var total = 0;
                                        var discountTotal = 0;
                                        var totalfee = 0;
                                        var discountUse = false;

                                        var discountTickets = [];

                                        //calculating the total and totalfee
                                        for (var i = 0; i < ticketsArray.length; i++){
                                            //checking if the type is the same for the discount and the ticket
                                            if(data.discount.ticket_type == ticketsArray[i][5]){
                                                var amount = ticketsArray[i][1];
                                                var price = ticketsArray[i][2];
                                                var ticketfee = parseFloat(ticketsArray[i][3]);

                                                discountTotal = discountTotal + (amount * price);
                                                totalfee = totalfee + (amount * ticketfee);
                                                discountUse = true;
                                            }else{
                                                var amount = ticketsArray[i][1];
                                                var price = ticketsArray[i][2];
                                                var ticketfee = parseFloat(ticketsArray[i][3]);

                                                total = total + (amount * price);
                                                totalfee = totalfee + (amount * ticketfee);
                                            }
                                        }

                                        //calculating the discount total minus the discount
                                        discountTotal = discountTotal - discount;

                                        //checking if the discount total is less than 0
                                        if(discountTotal <= 0){
                                            discountTotal = 0;
                                        }
                                        //checking if there has been a ticket where code applies to
                                        if(!discountUse){
                                            discount = 0;
                                        }

                                        //adding the normal total with the discounted total and the totalfee
                                        transactie_fee = 0;

                                        if (transactie){
                                            transactie_fee = price / 100 * 3.4;
                                            total += transactie_fee;
                                        }
                                        total = total + discountTotal + totalfee;

                                        //calculating discount for the reservation
                                        if(discountUse){
                                            //calculating total amount of discount tickets
                                            var totalAmount = 0;
                                            for(var j = 0; j < ticketsArray.length; j++){
                                                if(data.discount.ticket_type == ticketsArray[j][5]){
                                                    totalAmount = totalAmount + ticketsArray[j][1];
                                                }
                                            }
                                            //calculating discount per ticket
                                            var discountOneTicket = (discount / totalAmount);

                                            //saving ticketid + the discount for that ticket in array for reservation
                                            for (var k = 0; k < ticketsArray.length; k++){
                                                if(data.discount.ticket_type == ticketsArray[k][5]){
                                                    discountTickets.push([ticketsArray[k][0],discountOneTicket.toFixed(2)])
                                                }
                                            }
                                            document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);
                                        }




                                        document.getElementById('discount_check').value = 'true';
                                        document.getElementById('total_discount').value = discount.toFixed(2);

                                        document.getElementById('discount_total').innerText = total.toFixed(2);
                                        document.getElementById('discount_div').style.display = 'block';
                                        document.getElementById('discount').innerText = discount.toFixed(2);
                                    }

                                    break;

                                    //level 2: discount over the complete total of all the products of specific event
                                case 'event':
                                    var discount = parseFloat(data.discount.discount_value);

                                    var total = 0;
                                    var discountTotal = 0;
                                    var totalfee = 0;
                                    var discountUse = false;

                                    var discountTickets = [];

                                    //calculating the total and totalfee
                                    for (var i = 0; i < ticketsArray.length; i++){

                                        //checking if the type is the same for the discount and the ticket
                                        if(data.discount.ticket_type == ticketsArray[i][5]){
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            discountTotal = discountTotal + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                            discountUse = true;
                                        }else{
                                            var amount = ticketsArray[i][1];
                                            var price = ticketsArray[i][2];
                                            var ticketfee = parseFloat(ticketsArray[i][3]);

                                            total = total + (amount * price);
                                            totalfee = totalfee + (amount * ticketfee);
                                        }
                                    }

                                    //calculating the discount total minus the discount
                                    discountTotal = discountTotal - discount;

                                    //checking if the discount total is less than 0
                                    if(discountTotal <= 0){
                                        discountTotal = 0;
                                    }
                                    //checking if there has been a ticket where code applies to
                                    if(!discountUse){
                                        discount = 0;
                                    }

                                    //calculating discount for the reservation
                                    if(discountUse){
                                        //calculating total amount of discount tickets
                                        var totalAmount = 0;
                                        for(var j = 0; j < ticketsArray.length; j++){
                                            if(data.discount.ticket_type == ticketsArray[j][5]){
                                                totalAmount = totalAmount + ticketsArray[j][1];
                                            }
                                        }
                                        //calculating discount per ticket
                                        var discountOneTicket = (discount / totalAmount);

                                        //saving ticketid + the discount for that ticket in array for reservation
                                        for (var k = 0; k < ticketsArray.length; k++){
                                            if(data.discount.ticket_type == ticketsArray[k][5]){
                                                discountTickets.push([ticketsArray[k][0],discountOneTicket.toFixed(2)])
                                            }
                                        }
                                        document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);
                                    }

                                    transactie_fee = 0;


                                    if (transactie){
                                        transactie_fee = price / 100 * 3.4;
                                        total += transactie_fee;
                                    }
                                    //adding the normal total with the discounted total and the totalfee
                                    total = total + discountTotal + totalfee;

                                    document.getElementById('discount_check').value = 'true';
                                    document.getElementById('total_discount').value = discount.toFixed(2);

                                    document.getElementById('discount_total').innerText = total.toFixed(2);
                                    document.getElementById('discount_div').style.display = 'block';
                                    document.getElementById('discount').innerText = discount.toFixed(2);

                                    break;

                                case 'ticket':

                                    switch (data.discount.discount_lvl) {

                                        //level 1: discount on only one ticket
                                        case 1:
                                            var ticketId = data.discount.ticket_id;
                                            var discount = parseFloat(data.discount.discount_value);

                                            //temporary discount is for if the discount is more than what the ticket costs it will make the temporary discount the same price instead of more
                                            var temporary_discount = 0;

                                            var total = 0;
                                            var totalfee = 0;

                                            var discountTickets = [];

                                            //calculating the total and totalfee
                                            for (var i = 0; i < ticketsArray.length; i++){
                                                var amount = ticketsArray[i][1];
                                                var price = ticketsArray[i][2];
                                                var ticketfee = parseFloat(ticketsArray[i][3]);

                                                if(ticketsArray[i][0] == ticketId){
                                                    if(discount >= price){
                                                        temporary_discount = price;
                                                    }
                                                }
                                                total = total + (amount * price) + (amount * ticketfee);
                                                totalfee = totalfee + (amount * ticketfee);
                                            }

                                            //filling array for reservation
                                            for (var j = 0; j < ticketsArray.length; j++){
                                                if(ticketsArray[j][0] == ticketId){
                                                    if(ticketsArray[j][2] <= discount){
                                                        var discount2 = discount - (discount - ticketsArray[j][2])
                                                        discountTickets.push([ticketsArray[j][0],discount2.toFixed(2)]);
                                                    } else{
                                                        discountTickets.push([ticketsArray[j][0],discount.toFixed(2)]);
                                                    }

                                                }
                                            }

                                            //fill in hidden input with array for reservation
                                            document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);

                                            transactie_fee = 0;

                                            if (transactie){
                                                transactie_fee = price / 100 * 3.4;
                                                total += transactie_fee;
                                            }

                                            if(temporary_discount == 0){
                                                total = total - discount;
                                                document.getElementById('total_discount').value = discount.toFixed(2);
                                            }else{
                                                total = total - temporary_discount;
                                                document.getElementById('total_discount').value = parseFloat(temporary_discount).toFixed(2);
                                            }


                                            document.getElementById('discount_check').value = 'true';

                                            document.getElementById('discount_total').innerText = total.toFixed(2);
                                            document.getElementById('discount_div').style.display = 'block';
                                            document.getElementById('discount').innerText = discount.toFixed(2);
                                            break;
                                        case 2:
                                            $('#discount_error').html('<div class="alert alert-danger">This discount type needs to be used later. </div>')
                                            $('#discount_error').parent().find('.fa-spinner').remove()
                                            break;

                                        //level 3: discount on all the tickets of a specific ticket
                                        case 3:

                                            var ticketId = data.discount.ticket_id;
                                            var discount = parseFloat(data.discount.discount_value);
                                            var temporary_discount = 0;

                                            var total = 0;
                                            var totalfee = 0;

                                            var discountTickets = [];

                                            var discountOneTicket = 0;

                                            //calculating the discount for every one ticket
                                            for(var i = 0; i < ticketsArray.length; i++){
                                                if(ticketId == ticketsArray[i][0]){
                                                    discountOneTicket = discount / ticketsArray[i][1];
                                                    discountTickets.push([ticketsArray[i][0],discountOneTicket.toFixed(2)])
                                                }
                                            }


                                            //calculating the total and totalfee
                                            for (var i = 0; i < ticketsArray.length; i++){
                                                if(ticketId == ticketsArray[i][0]){
                                                    var amount = ticketsArray[i][1];
                                                    var price = ticketsArray[i][2];
                                                    var ticketfee = parseFloat(ticketsArray[i][3]);

                                                    total = total + (amount * price);
                                                    var temporary_total = total;
                                                    totalfee = totalfee + (amount * ticketfee);

                                                    total = total - discount;
                                                    if(total <= 0){
                                                        total = 0  + (amount * ticketfee);
                                                        temporary_discount = discount - (discount - temporary_total);
                                                    }
                                                } else{
                                                    var amount = ticketsArray[i][1];
                                                    var price = ticketsArray[i][2];
                                                    var ticketfee = parseFloat(ticketsArray[i][3]);

                                                    total = total + (amount * price) + (amount * ticketfee);
                                                    totalfee = totalfee + (amount * ticketfee);
                                                }
                                            }
                                            transactie_fee = 0;

                                            if (transactie){
                                                transactie_fee = price / 100 * 3.4;
                                                total += transactie_fee;
                                            }
                                            //fill in hidden input with array for reservation
                                            document.getElementById('discount_tickets').value = JSON.stringify(discountTickets);

                                            document.getElementById('discount_check').value = 'true';
                                            if(temporary_discount == 0){
                                                document.getElementById('total_discount').value = discount.toFixed(2);
                                            }else{
                                                document.getElementById('total_discount').value = temporary_discount.toFixed(2);
                                            }


                                            document.getElementById('discount_total').innerText = total.toFixed(2);
                                            document.getElementById('discount_div').style.display = 'block';
                                            document.getElementById('discount').innerText = discount.toFixed(2);
                                            break;
                                    }

                                    break;
                            }
                            break;
                    }


                } else{
                    $('#discount_error').html('<div class="alert alert-danger">The discount code is not valid. </div>')
                    $('#discount_error').parent().find('.fa-spinner').remove()
                }},
            error: function () {
                $('#discount_error').html('<div class="alert alert-danger">The discount code is not valid. </div>')
                $('#discount_error').parent().find('.fa-spinner').remove()
            }
        });
    } else{
        $('#discount_error').html('<div class="alert alert-danger">Fill the field. </div>')
        $('#discount_error').parent().find('.fa-spinner').remove()
    }

});


//increase ticket function for discount
$('.increseTicket').on('mouseup', function () {
    var parentElement = this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    var eventBlock = parentElement.querySelector('.event-block');
    //getting the amount of selected tickets
    var maxAmount = parseInt(eventBlock.getAttribute('data-max'));
    var amount = parseInt(parentElement.querySelector('.ticket_amount').innerHTML) + 1;
    if(amount >= maxAmount){
        amount = maxAmount;
    }
    //getting the id of the selected ticket
    const ticketId = eventBlock.getAttribute('data-ticket');

    //getting the type of the selected ticket
    const ticketType = eventBlock.getAttribute('data-type');

    //getting ticket price
    const ticketprice = eventBlock.getAttribute('data-price');


    //getting the fee
    const fee = eventBlock.getAttribute('data-service');

    //getting name of the ticket
    const ticketName = eventBlock.getAttribute('data-name');

    //changing value of hidden input
    var ticketsArray = [];
    const productsSelected = document.getElementById('productsSelected').value;

    var tijdelijkeArray = [];

    //check if there is already a ticket selected
    if(productsSelected){
        ticketsArray = JSON.parse(productsSelected);

        for (var i = 0; i < ticketsArray.length; i++){
            tijdelijkeArray.push(ticketsArray[i][0])
        }

        if(tijdelijkeArray.includes(ticketId)){
            for(var i = 0; i < ticketsArray.length; i++){
                if(ticketsArray[i][0] == ticketId){
                    ticketsArray[i][1] = amount;
                }
            }
        }else{
            ticketsArray.push([ticketId,amount,ticketprice,fee,ticketName,ticketType]);
        }

    }else{
        ticketsArray.push([ticketId,amount,ticketprice,fee,ticketName,ticketType]);
    }

    //setting hidden input to the array of all selected tickets
    document.getElementById('productsSelected').value = JSON.stringify(ticketsArray);

    var total = 0;
    var totalfee = 0;
    var transactie = eventBlock.getAttribute('data-transactie');


    //calculating the total and totalfee
    for (var i = 0; i < ticketsArray.length; i++){
        var amount = ticketsArray[i][1];
        var price = ticketsArray[i][2];
        var ticketfee = parseFloat(ticketsArray[i][3]);

        total = total + (amount * price) + (amount * ticketfee);
        totalfee = totalfee + (amount * ticketfee);
    }
    var transactie_fee = price / 100 * 3.4;

    var ticketsDiv = document.getElementById('discount_tickets_div');
    ticketsDiv.innerHTML = '';
    //setting the rows that show the information of the selected tickets
    for (var i = 0; i < ticketsArray.length; i++){
        var price = parseFloat(ticketsArray[i][2]);

        if (transactie){
            price = price / 100 * 103.4;
            total += transactie_fee;
        }
        ticketsDiv.innerHTML += '<div class="form-inline row"><p class="col-md-4 font-weight-bold">'+ ticketsArray[i][4] +'</p><p class="col-md-4">Price €' + price.toFixed(2) +'</p><p class="col-md-4">Amount: ' + ticketsArray[i][1] + '</p></div>';
    }

    //set the totalfee element
    document.getElementById('discount_totalfee').innerText = totalfee.toFixed(2);
    //set the total element
    document.getElementById('discount_total').innerText = total.toFixed(2);

});



//decrease ticket function for discount
$('.decreseTicket').on('click', function () {
    var parentElement = this.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    var eventBlock = parentElement.querySelector('.event-block');
    //getting the amount of selected tickets
    var maxAmount = parseInt(eventBlock.getAttribute('data-max'));
    var amount = parseInt(parentElement.querySelector('.ticket_amount').innerHTML) - 1;
    if(amount >= maxAmount){
        amount = maxAmount;
    }
    //getting the id of the selected ticket
    var ticketId = eventBlock.getAttribute('data-ticket');

    //getting the type of the selected ticket
    const ticketType = eventBlock.getAttribute('data-type');

    //changing value of hidden input
    var ticketsArray = [];
    var productsSelected = document.getElementById('productsSelected').value;

    var tijdelijkeArray = [];

    if(productsSelected){
        ticketsArray = JSON.parse(productsSelected);

        for (var i = 0; i < ticketsArray.length; i++){
            tijdelijkeArray.push(ticketsArray[i][0])
        }

        if(tijdelijkeArray.includes(ticketId)){
            for(var i = 0; i < ticketsArray.length; i++){
                if(ticketsArray[i][0] == ticketId){
                    if(amount == 0){
                        ticketsArray.splice(i, 1);
                    } else{
                        ticketsArray[i][1] = amount;
                    }

                }
            }
        }

    }
    document.getElementById('productsSelected').value = JSON.stringify(ticketsArray);

    var total = 0;
    var totalfee = 0;


    //calculating the total and totalfee
    for (var i = 0; i < ticketsArray.length; i++){
        var amount = ticketsArray[i][1];
        var price = ticketsArray[i][2];
        var ticketfee = parseFloat(ticketsArray[i][3]);

        total = total + (amount * price) + (amount * ticketfee);
        totalfee = totalfee + (amount * ticketfee);
    }

    var ticketsDiv = document.getElementById('discount_tickets_div');
    ticketsDiv.innerHTML = '';


    //setting the rows that show the information of the selected tickets
    for (var i = 0; i < ticketsArray.length; i++){
        const price = parseInt(ticketsArray[i][2]);

        ticketsDiv.innerHTML += '<div class="form-inline row"><p class="col-md-4 font-weight-bold">'+ ticketsArray[i][4] +'</p><p class="col-md-4">Price €' + price.toFixed(2) +'</p><p class="col-md-4">Amount: ' + ticketsArray[i][1] + '</p></div>';
    }


    //set the totalfee element
    document.getElementById('discount_totalfee').innerText = totalfee.toFixed(2);
    //set the total element
    document.getElementById('discount_total').innerText = total.toFixed(2);

});
