function count_characters(obj, colorID, countID) {
    if(obj.value.length >= obj.maxLength){
        document.getElementById(colorID).style.color = "red";
    }else{
        document.getElementById(colorID).style.color = "black";
    }

    document.getElementById(countID).innerHTML = obj.value.length;
}
window.count_characters = count_characters;
