$(document).ready(function(){
    var text = $('#event_type').find(":selected").text();
    var send_what = $('#send_what').find(":selected").val();
    var select_display = $('#select_display').find(":selected").val();
    var affiliate_toggle = $('#affiliate_toggle').is(":checked");

    if(text === 'Online Event')
    {
        $("#not-online").hide();
        $("#online").show();
        $("#scheduling").show();
    }else
    {
        $("#not-online").show();
        $("#online").hide();
        $("#scheduling").hide();
    }


    if (text === 'Timeslots'){
        $("#timeslot").show();
    }else{
        $("#timeslot").hide();
    }

    if(send_what !== 'ticket')
    {
        $("#display").show();
    }
    else
    {
        $("#display").hide();
    }

    if(select_display !== '1')
    {
        $("#ticket_quantity").show();
    }
    else
    {
        $("#ticket_quantity").hide();
    }

    if(affiliate_toggle)
    {
        $("#affiliate_percentage_div").show();
    }
    else
    {
        $("#affiliate_percentage_div").hide();
    }

    $("#event_type").change(function(){
        var text = $('#event_type').find(":selected").text();

        if(text === 'Online Event')
        {
            $("#not-online").hide();
            $("#online").show();
            $("#scheduling").show();
        } else {
            $("#not-online").show();
            $("#online").hide();
            $("#scheduling").hide();
        }

        if (text === 'Timeslots'){
            $("#timeslot").show();
        }else{
            $("#timeslot").hide();
        }

    });

    $("#send_what").change(function(){
        var send_what = $('#send_what').find(":selected").val();
        if(send_what !== 'ticket')
        {
            $("#display").show();
        }
        else
        {
            $("#display").hide();
        }
    });

    $("#select_display").change(function(){
        var select_display = $('#select_display').find(":selected").val();
        if(select_display !== '1')
        {
            $("#ticket_quantity").show();
        }
        else
        {
            $("#ticket_quantity").hide();
        }
    });

    $("#affiliate_toggle").change(function(){
        var affiliate_toggle = $('#affiliate_toggle').is(":checked");
        if(affiliate_toggle)
        {
            $("#affiliate_percentage_div").show();
        }
        else
        {
            $("#affiliate_percentage_div").hide();
        }
    });
});
