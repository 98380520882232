/*
    Toggle extra information per ticket in organisation Event edit
     */
document.addEventListener('DOMContentLoaded', function() {
    $('#extra_info_status').is(':checked') ?
        $('#extraInformation').collapse('show') :
        $('#extraInformation').collapse('hide')
    $('#extra_info_status').on('click', function() {
        $('#extra_info_status').is(':checked') ?
            $('#extraInformation').collapse('show') :
            $('#extraInformation').collapse('hide')
    })
})

$('#scanned_tickets').click(function() {
    if($('#unscanned_tickets').is(':checked')) {
        $('#unscanned_tickets').prop('checked', false);
    }
});

$('#unscanned_tickets').click(function() {
    if($('#scanned_tickets').is(':checked')) {
        $('#scanned_tickets').prop('checked', false);
    }
});
