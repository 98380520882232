import 'croppie'

$(function () {

  var $uploadCrop

  function readFile (input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader()
      var parent = $(input).parent().parent().parent();


      reader.onload = function (e) {
        parent.addClass('ready')
        $uploadCrop.croppie('bind', {
          url: e.target.result
        }).then(function () {
          console.log('jQuery bind complete')
        })

      }

      reader.readAsDataURL(input.files[0])
    }
    else {
      swal('Sorry - you\'re browser doesn\'t support the FileReader API')
    }
  }

  $uploadCrop = $('#upload-poster').croppie({
    viewport: {
      width: 200,
      height: 284,
    },
    boundary: { width: 200, height: 284 },

    enableExif: true
  })

  $('#upload-poster-file').on('change', function () { readFile(this) })
  $('.upload-poster-result').on('click', function (ev) {
    var mainDiv = $(this).parent().parent();

    var uploadField = $(this).parent().parent().find('input.cropy-img-data');
    ev.preventDefault()
    $uploadCrop.croppie('result', {
      type: 'base64',
      size: {
        width: 400,
        height: 567,
      }
    }).then(function (resp) {
      console.log(resp)
      uploadField.val(resp)
      mainDiv.find('.upload-poster-msg img').attr('src', resp)
      mainDiv.removeClass('ready')

    })
  })




  var $uploadBannerCrop

  function readFileBanner (input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader()
      var parent = $(input).parent().parent().parent();


      reader.onload = function (e) {
        parent.addClass('ready')
        $uploadBannerCrop.croppie('bind', {
          url: e.target.result
        }).then(function () {
          console.log('jQuery bind complete')
        })

      }

      reader.readAsDataURL(input.files[0])
    }
    else {
      swal('Sorry - you\'re browser doesn\'t support the FileReader API')
    }
  }

  $uploadBannerCrop = $('#upload-banner').croppie({
    viewport: {
      width: 200,
      height: 74,
    },
    boundary: { width: 200, height: 74 },

    enableExif: true
  })

  $('#upload-banner-file').on('change', function () { readFileBanner(this) })
  $('.upload-banner-result').on('click', function (ev) {
    var mainDiv = $(this).parent().parent();

    var uploadField = $(this).parent().parent().find('input.cropy-img-data');
    ev.preventDefault()
    $uploadBannerCrop.croppie('result', {
      type: 'base64',
      size: {
        width: 1300,
        height: 480,
      }
    }).then(function (resp) {
      console.log(resp)
      uploadField.val(resp)
      mainDiv.find('.upload-banner-msg img').attr('src', resp)
      mainDiv.removeClass('ready')

    })
  })


})
