import 'daterangepicker'
import 'bootstrap-toggle'
import * as moment from 'moment'

$(function () {
  'use strict'
  $('[data-toggle="tooltip"]').tooltip()

  $('[data-toggle="popover"]').popover()

  $('.togglebutton').bootstrapToggle({
    on: 'Enabled',
    off: 'Disabled'
  });

  $('[data-toggle="html-modal"]').click(function (e) {
    e.preventDefault()
    const modalElem = $('#htmlModal')

    modalElem.removeClass('slide-up')
    modalElem.removeClass('stick-up')

    const title = $(this).data('m-title')
    const url = $(this).data('m-url')
    let type = $(this).data('m-type')
    if (!type) {
      type = 'slide-up'
    }
    modalElem.children().find('.m-title').html(title)
    modalElem.children().find('.modal-body').html('')
    modalElem.addClass(type)
    modalElem.modal('show')

    $.ajax({
      url: url,
      data: {},
      success: function (data) {
        modalElem.children().find('.modal-body').html(data)
        initPlugins()
      },
      dataType: 'html'
    })

  })

  $('.editVenue').click(function (e) {
    e.preventDefault()
    $(this).parent().hide()
    $('.editVenueBox').removeClass('hidden')
  })

  function initPlugins () {
    $('.input-daterange-timepicker').daterangepicker({
      timePicker: true,
      timePicker24Hour: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    })

    $('.input-datetimepicker').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    })

    $('.birthday-picker').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minDate: moment('1936-01-01'),
      maxDate: moment().subtract(18, 'years'),
      locale: {
        format: 'DD-MM-YYYY'
      }
    })

    $('.datepicker').daterangepicker({
      singleDatePicker: true,
      locale: {
        format: 'DD-MM-YYYY'
      }
    })
  }

  initPlugins()

  if ($('.alert-fixed').length) {
    setTimeout(function () {
      $('.alert-fixed').hide('slow')
    }, 8000)
  }

  if ($('#editFields').length) {

    $('#logo').change(function () {
      var _this = $(this)
      _this.before('<i class="fa fa-spin  fa-spinner"></i>')
      if (this.files && this.files[0]) {
        var fileSize = this.files[0].size // in bytes
        if (fileSize > 1024 * 1024 * 2) {
          return false
        }
        _this.parent().find('.alert-danger').remove()
        var reader = new FileReader()
        reader.onload = function (e) {
          $.ajax({
            type: 'POST',
            url: $('#editFields').data('url'),
            data: {logo: e.target.result, _method: 'PUT'},
            success: function (data) {
              _this.parent().find('.fa-spinner').remove()
              if (data.error) {
                _this.before('<div class="alert alert-danger"> Error changing logo. </div>')
              } else {
                _this.parent().find('.previewImg img').attr('src', e.target.result)
                _this.parent().find('.alert-danger').remove()
                _this.before('<div class="alert alert-success"> Logo updated. </div>')
                setTimeout(function () {
                  _this.parent().find('.alert-success').remove()
                }, 3000)
              }
            },
            error: function (e) {
              _this.parent().find('.fa-spinner').remove()
              _this.before('<div class="alert alert-danger"> An error has occurred please try again later. </div>')
            },
            dataType: 'json'
          })

        }
        reader.readAsDataURL(this.files[0])
      }

    })

    $('#editFields .editbtn').click(function (e) {
      e.preventDefault()
      var editFields = $(this).parent().find('.editFields')
      $(this).parent().find('.' + editFields.data('type')).hide()
      $(this).hide()
      editFields.removeClass('hidden')
    })

    $('#editFields .saveBtn').click(function (e) {
      e.preventDefault()
      var _this = $(this)
      var data = $(this).parent().find(':input').serializeArray()
      data.push({name: '_method', value: 'PUT'})

        $('.validation_errors').remove()
        $('input').removeClass('border-danger')

      _this.prepend('<i class="fa fa-spin  fa-spinner"></i>')
      _this.prop('disabled', true)

      $.ajax({
        type: 'POST',
        url: $('#editFields').data('url'),
        data: data,
        success: function (data) {
          console.log(data)
          _this.find('.fa-spinner').remove()
          if (data.error) {
            console.log(data.error)
            _this.parent().before('<div class="alert alert-danger"> Please fill in all the fields. </div>')
          } else {
            var editFields = _this.parent().parent().find('.editFields')
            _this.parent().parent().find('.alert').remove()
            _this.parent().parent().find('.' + editFields.data('type')).show()
            _this.parent().parent().find('.' + editFields.data('type')).html(data.data)
            _this.parent().parent().find('.' + editFields.data('type')).show()
            _this.parent().parent().find('.editbtn').show()
            editFields.addClass('hidden')
          }
        },
        error: function (e) {
          _this.find('.fa-spinner').remove()

        if (data[0].name == 'slug'){
          _this.parent().before('<div class="alert alert-danger"> Slug already exists try another slug </div>')
        }
          // if (data[0].name == 'name'){
          //   _this.parent().before('<div class="alert alert-danger"> Name required</div>')
          // }
          if (data[0].name == 'email'){
            _this.parent().before('<div class="alert alert-danger"> Email required </div>')
          }
          else if (data[0].value !== data[1].value && data[0].name == 'password') {
            console.log(data[0])
            _this.parent().before('<div class="alert alert-danger"> Password combination not correct. Please try again.\n </div>')
          }else {
            // _this.parent().before('<div class="alert alert-danger"> An error has occurred please try again later. </div>')
              for(let key in e.responseJSON.errors) {
                  $('[name=\"' + key + '\"]').addClass('border-danger')
                  $('[name=\"' + key + '\"]').parent().append(`<div class="validation_errors"><p class="text-danger mt-2"><i data-feather="alert-circle"></i>${e.responseJSON.errors[key][0]}</p></div>`)
              }
          }
        },
        dataType: 'json'
      })

    })

  }

  $('table.clickablerow tr').click(function (e) {
    console.log(window.event.metaKey)
    const url = $(this).attr('data-url')
    if (url) {
      if (window.event.metaKey) {
        const win = window.open(url, '_blank')
        if (win) {
          win.focus()
        } else {
          window.location.href = url
        }
      } else {
        window.location.href = url
      }
    }
  })

})

